import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'

const IndexPage = () => (
  <>
  </>
)

export default IndexPage
